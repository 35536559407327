<template>
  <div class="context">
    <div class="animate__animated animate__wobble animate__repeat-2">
      <button class="button-85" role="button" @click="handleClick">这里没有<br />你想要找的东西</button>
    </div>
    <div class="footer">
      <div class="footerLine"></div>
      <div class="footerInfo">
        <div class="webInfo">@ROADRUNNER2002.top</div>
        <div class=""></div>
      </div>
      <div class="footerInfo">
        <a class="record" style="color: gray;" href="https://beian.miit.gov.cn/" target="_blank">滇ICP备2022005390号-1</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
    }
  },
  methods: {
    handleClick(e) {
      const element = e.target
      element.classList.add('animate__animated', 'animate__wobble')
      element.addEventListener('animationend', () => {
        element.classList.remove('animate__animated', 'animate__wobble');
        element.removeEventListener('animationend', () => {
          console.log("这里也什么都没有")
        })
      })
    },
  },
  mounted: function () {
    console.log("这里也什么都没有")
  }
}
</script>

<style lang="scss">
* {
  margin: 0px;
  padding: 0px;
}

html,
body,
#app {
  width: 100%;
  height: 100%;
  background-color: whitesmoke;

}

.context {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(to right, #4facfe 0%, #00f2fe 100%);
  position: relative;

  .footer {
    font-size: 14px;
    position: absolute;
    bottom: 10px;
  }
}

.button-85 {
  
  padding: 0.6em 2em;
  border: none;
  outline: none;
  color: rgb(255, 255, 255);
  background: #111;
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 10px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 18px;
}

.button-85:before {
  content: "";
  background: linear-gradient(45deg,
      #ff0000,
      #ff7300,
      #fffb00,
      #48ff00,
      #00ffd5,
      #002bff,
      #7a00ff,
      #ff00c8,
      #ff0000);
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  -webkit-filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing-button-85 20s linear infinite;
  transition: opacity 0.3s ease-in-out;
  border-radius: 10px;
}

@keyframes glowing-button-85 {
  0% {
    background-position: 0 0;
  }

  50% {
    background-position: 400% 0;
  }

  100% {
    background-position: 0 0;
  }
}

.button-85:after {
  z-index: -1;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: #222;
  left: 0;
  top: 0;
  border-radius: 10px;
}

/* #app {
  width: 100vw;
  height: 100vh;
} */
</style>